import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { postData } from "../../../serverRequest";
import LoadingSpinner from "../../../components/Loadder";
import { Link } from "react-router-dom";
// components
import NewRequestCard from "../Cards/NewRequestCard.js";

export default function OnGoingService() {
  const [errorMessages, setErrorMessages] = useState({});
  const [serviceList, setServiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(['token']);
  const history = useHistory();
  let shouldRenderContent = true;

  // User Login info
  const database = [
      {
          username: "user1",
          password: "pass1"
      },
      {
          username: "user2",
          password: "pass2"
      }
  ];

  const errors = {
      uname: "invalid username",
      pass: "invalid password"
  };

  useEffect(() => {
      const cookies = document.cookie.split(';');
      console.log(cookies);
      if(cookies){
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.startsWith('token=')) {
              let token = cookie.substring(6);
              if (token) {
                  shouldRenderContent = false;
                  history.push('/engineer/');
              }
          }
      }
      }
      else{
        window.location.href = '/';
      }


  }, [shouldRenderContent, history]);

  return (
    <>
      {/* Header */}
      <div className="relative md:pt-32 pb-32 pt-12">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {serviceList.length>0?<>
              <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-6/12 px-6">
                <NewRequestCard
                  statSubtitle="Desktop Issue"
                  statTitle="800 Rs"
                  statArrow=""
                  statPercent=""
                  statPercentColor=""
                  statDescripiron="F6, Fortune Homes, Basapura Main Road"
                  statIconName="far fa-map"
                  statIconColor="bg-red-500"
                />
                
              </div>
              <div className="w-full lg:w-6/12 xl:w-6/12 px-4">
              <NewRequestCard
                  statSubtitle="Desktop Issue"
                  statTitle="800 Rs"
                  statArrow=""
                  statPercent=""
                  statPercentColor=""
                  statDescripiron="F6, Fortune Homes, Basapura Main Road"
                  statIconName="far fa-map"
                  statIconColor="bg-red-500"
                />
              </div>
            </div>
            <div className="flex flex-wrap pt-12">
              <div className="w-full lg:w-6/12 xl:w-6/12 px-4">
              <NewRequestCard
                  statSubtitle="Desktop Issue"
                  statTitle="800 Rs"
                  statArrow=""
                  statPercent=""
                  statPercentColor=""
                  statDescripiron="F6, Fortune Homes, Basapura Main Road"
                  statIconName="far fa-map"
                  statIconColor="bg-red-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-6/12 px-4">
              <NewRequestCard
                  statSubtitle="Desktop Issue"
                  statTitle="800 Rs"
                  statArrow=""
                  statPercent=""
                  statPercentColor=""
                  statDescripiron="F6, Fortune Homes, Basapura Main Road"
                  statIconName="far fa-map"
                  statIconColor="bg-red-500"
                />
              </div>
            </div>
            </>
            
:<>
           <div className="flex flex-wrap pt-4 mt-48">
            <p className="text-center">There is no ticket assigned to you yet.</p>
           </div>
</>}

          </div>
        </div>
      </div>
    </>
  );
}
