import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { postData } from "../../../serverRequest";
import LoadingSpinner from "../../../components/Loadder";
import { Link } from "react-router-dom";

export default function Register() {
    const [errorMessages, setErrorMessages] = useState({});
    const [loading, setLoading] = useState(false);
    const [cookies, setCookie] = useCookies(['token']);
    const [isDuplicate, setIsDuplicate] = useCookies(false);
    const history = useHistory();
    let shouldRenderContent = true;

    // User Login info
    const database = [
        {
            username: "user1",
            password: "pass1"
        },
        {
            username: "user2",
            password: "pass2"
        }
    ];

    const errors = {
        uname: "invalid username",
        pass: "invalid password"
    };

    useEffect(() => {
        const cookies = document.cookie.split(';');
        console.log(cookies);
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith('token=')) {
                let token = cookie.substring(6);
                if (token) {
                    shouldRenderContent = false;
                    history.push('/user/dashboard');
                }
            }
        }

    }, [shouldRenderContent, history]);

    const handleSubmit = async (event) => {
        //Prevent page reload
        event.preventDefault();
        try {
            setLoading(true);
            let { fname, email, mobile, pass, cpass } = document.forms[0];
            if(pass.value!==cpass.value){
                setLoading(false);
                setErrorMessages({ name: "pass", message: errors.cpass });
            }
            let reqData = {
                'fname': fname.value,
                'password': pass.value,
                'email': email.value,
                'mobile': mobile.value
            }
            let resData = await postData('/api/engineer/', reqData)
            console.log("This is data")
            console.log(resData)
            if (resData['data']['code'] === 200) {
                var employee_id=resData['data']['data']['id'];
                window.location.href = '/verify/?eng=' + employee_id;
            }
            else if (resData['data']['code'] === 403) {
                setLoading(false);
                setErrorMessages({ name: "pass", message: errors.pass });
            }
            else {
                setLoading(false);
                setErrorMessages({ name: "uname", message: errors.uname });
            }

        } catch (error) {
            // Handle errors
            console.error(error);
        }
    };

    // Generate JSX code for error message
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );
    if (shouldRenderContent) {
        return (
            <>
                {/* <Navbar transparent /> */}
                <main>
                    {loading ? (
                        <LoadingSpinner></LoadingSpinner>
                    ) : (
                        <section className="relative w-full h-full py-24 min-h-screen">
                            <div
                                className="absolute top-0 w-full h-full bg-white bg-no-repeat bg-full"
                                style={{
                                    backgroundImage:
                                        "url(" + require("../../../assets/img/bg.jpg") + ")",
                                }}
                            ></div>
                            <div className="container mx-auto px-4 h-full">
                                <div className="flex content-center items-center justify-center h-full">
                                    <div className="w-full lg:w-4/12 px-4">
                                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-black-50 border-0">
                                            <div className="rounded-t mb-0" style={{ backgroundColor: '#ffb703' }}>
                                                <div className="text-center pt-2 items-center">
                                                    <img
                                                        alt="..."

                                                        src={require("../../../assets/img/Get_IT_Logo_Jpeg.jpg")}
                                                    />
                                                </div>

                                                <hr className="mt-6 border-b-1 border-blueGray-300" />
                                            </div>
                                            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                                <div className="text-white text-center mb-3 mt-8 font-bold text-lg">
                                                    <large>Onboarding</large>
                                                </div>
                                                <form onSubmit={handleSubmit}>
                                                    <div className="relative w-full mb-4">
                                                        <label
                                                            className="block uppercase text-white text-xs font-bold mb-3"
                                                            htmlFor="grid-password"
                                                        >
                                                            Full Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            placeholder="Full Name"
                                                            name="fname"
                                                            required
                                                        />
                                                        {renderErrorMessage("uname")}
                                                    </div>

                                                    <div className="relative w-full mb-4">
                                                        <label
                                                            className="block uppercase text-white text-xs font-bold mb-3 mt-3"
                                                            htmlFor="grid-password"
                                                        >
                                                            Email ID
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            placeholder="Email"
                                                            name="email"

                                                            required
                                                        />
                                                        {renderErrorMessage("pass")}
                                                    </div>
                                                    <div className="relative w-full mb-4">
                                                        <label
                                                            className="block uppercase text-white text-xs font-bold mb-3"
                                                            htmlFor="grid-password"
                                                        >
                                                            Mobile Number
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            placeholder="Mobile"
                                                            name="mobile"
                                                            required
                                                        />
                                                        {renderErrorMessage("uname")}
                                                    </div>

                                                    <div className="relative w-full mb-4">
                                                        <label
                                                            className="block uppercase text-white text-xs font-bold mb-3 mt-3"
                                                            htmlFor="grid-password"
                                                        >
                                                            Password
                                                        </label>
                                                        <input
                                                            type="password"
                                                            className="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            placeholder="Password"
                                                            name="pass"
                                                            required
                                                        />
                                                        {renderErrorMessage("pass")}
                                                    </div>
                                                    <div className="relative w-full mb-4">
                                                        <label
                                                            className="block uppercase text-white text-xs font-bold mb-3 mt-3"
                                                            htmlFor="grid-password"
                                                        >
                                                            Confirm Password
                                                        </label>
                                                        <input
                                                            type="password"
                                                            className="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            placeholder="Password"
                                                            name="cpass"
                                                            required
                                                        />
                                                        {renderErrorMessage("pass")}
                                                    </div>
                                                    {/* <div>
                                                <label className="inline-flex items-center cursor-pointer">
                                                    <input
                                                        id="customCheckLogin"
                                                        type="checkbox"
                                                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                    />
                                                    <span className="ml-2 text-sm font-semibold text-blueGray-600">
                                                        Remember me
                                                    </span>
                                                </label>
                                            </div> */}

                                                    <div className="text-center mt-6">
                                                        {/* <Link to="/verify" className="bg-yellow text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150">
                                                            <small>Submit</small>
                                                        </Link> */}
                                                        <input
                                                            className="bg-yellow text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                                            type="Submit"
                                                            value="Submit"
                                                        >

                                                        </input>
                                                    </div>
                                                </form>
                                                <div className="flex flex-wrap mt-6 relative">

                                                    {/* <div className="w-1/2 text-right">
                                                        <Link to="/" className="text-white">
                                                            <small>Sign In</small>
                                                        </Link>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                    }

                </main>
                {/* <LoginFooter></LoginFooter> */}
            </>
        );
    }
}
