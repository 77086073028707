import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import './index.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/styles/tailwind.css";
import Login from "./views/User/Login/index";
// import User from './views/User';
// import Admin from './views/Admin';
import ForgotPasswords from './views/ForgotPassword';
import Register from './views/User/Register/index';
import Verification from './views/User/Register/verification';
import FinishRegistration from './views/User/Register/engineerDetails';
import Engineer from './views/Engineer';
import VerifyUser from './views/User/ForgotPassword/verifyUser';
import UpdatePassword from './views/User/ForgotPassword/updatePassword';
import ForgotPassword from './views/User/ForgotPassword';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
 
      <Route path="/" exact component={Login} />
      <Route path="/login" exact component={Login} />
      <Route path="/fpassword" exact component={ForgotPassword} />
      <Route path="/vuser" exact component={VerifyUser} />
      <Route path="/updatepassword" exact component={UpdatePassword} />
      <Route path="/register" exact component={Register} />
      <Route path="/verify" exact component={Verification} />
      <Route path="/finish" exact component={FinishRegistration} />
      <Route path="/engineer" component={Engineer} />
      {/* <Route path="/admin" component={Admin} /> */}
      {/* add redirect for first page */}
      
    </Switch>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//gtitadmin
//Mnbvfgh1234

//premimum.care@indusind.com