import React from "react";

import UserDropdown from "../../../components/Dropdowns/UserDropdown.js";
import { Link } from "react-router-dom";
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import testimonial from '../../../assets/img/testimonial.png';
import { getData, postData } from "../../../serverRequest";
import { Fragment } from 'react'
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

export default function EngineerNavbar() {
    const [navbarOpen, setNavbarOpen] = React.useState(false);
    const [userName, setUserName] = React.useState(localStorage.getItem('userName'));

    const handleLogout = async () => {
        // Clear the cookies

        let resData = await getData('/api/engineer/logout/');
        if (resData['data']['code'] === 200) {
            await clearCookies();
            alert('You have been logged out.');
            window.location.href = '/';
        }
        else{
          await clearCookies();
          alert('You have been logged out.');
          window.location.href = '/';
        }

    };

    const clearCookies = () => {
        // Get all cookies by splitting the document.cookie string by ';'
        const cookies = document.cookie.split(';');

        // Loop through each cookie and set its expiration date to the past
        cookies.forEach(cookie => {
            const cookieName = cookie.trim().split('=')[0];
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        });
    };
    return (
        <>
            {/* Navbar */}
            <nav className="absolute top-0 left-0 w-full z-10  md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
                <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
                    {/* Brand */}

                    {/* Form */}

                    {/* User */}
                    <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                        <li className="flex items-center">
                            <p
                                className="hover:text-black text-black px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                            >
                                {userName}
                            </p>
                        </li>
                        <li className="flex items-center">
                            <Menu as="div" className="relative ml-3">
                                <div>
                                    <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                        <span className="sr-only">Open user menu</span>
                                        <img
                                            className="h-8 w-8 rounded-full"
                                            src={testimonial}
                                            alt=""
                                        />
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {/* <Menu.Item>
                        {({ active }) => (
                          <a
                            href="/user/profile"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Your Profile
                          </a>
                        )}
                      </Menu.Item> */}

                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href="#"
                                                    onClick={handleLogout}
                                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                >
                                                    Sign out
                                                </a>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </li>
                    </ul>
                </div>
            </nav>
            {/* End Navbar */}
        </>
    );
}
