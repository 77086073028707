import axios from 'axios';
// var serverUrl = 'http://localhost:8001';
var serverUrl = 'https://dsvh67t815lsw.cloudfront.net';

export async function getData(url) {
  let requesturl = serverUrl + url;
  let response_data = {
    'code': 500,
    'message': "There is some issue in request",
    'data': ''
  }
  let token = '';
  const cookies = document.cookie.split(';');
  //console.log(cookies);
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith('token=')) {
      token = cookie.substring(6);
    }
  }
  //console.log(token);
  await axios.get(requesturl, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
      // Add other headers as needed
    }
  })
    .then(res => {
      response_data['code'] = 200;
      response_data['data'] = res.data;
    }).catch(error => {
      console.log(error)
    })
  return response_data
}

export async function postData(url, requested_data) {
  let response_data = {
    'code': 500,
    'message': "There is some issue in request",
    'data': ''
  }
  let requesturl = serverUrl + url;
  let token = '';
  //const cookiest = document.cookie;
  const cookies = document.cookie.split(';');
  //console.log(cookies);
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith('token=')) {
      token = cookie.substring(6);
    }
  }
  //console.log(token);
  await axios.post(requesturl, requested_data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
      // Add other headers as needed
    }
  })
    .then(res => {
      response_data['code'] = 200;
      response_data['data'] = res.data;
    })
  return response_data
  //return response_data
}

export async function postFormData(url, requested_data) {
  let response_data = {
    'code': 500,
    'message': "There is some issue in request",
    'data': ''
  }
  let requesturl = serverUrl + url;
  let token = '';
  //const cookiest = document.cookie;
  const cookies = document.cookie.split(';');
  //console.log(cookies);
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith('token=')) {
      token = cookie.substring(6);
    }
  }
  //console.log(token);
  await axios.post(requesturl, requested_data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      //'Authorization': token,
      // Add other headers as needed
    }
  })
    .then(res => {
      response_data['code'] = 200;
      response_data['data'] = res.data;
    })
  return response_data

}
//https://gettargetit.auth.ap-southeast-1.amazoncognito.com/login?response_type=code&client_id=1sp43hq7i6ikithm21cpltskgn&redirect_uri=https://gettargetit.com
//https://gettargetit.auth.ap-southeast-1.amazoncognito.com/signup?response_type=code&client_id=1sp43hq7i6ikithm21cpltskgn&redirect_uri=https://gettargetit.com

//https://gettargetit.auth.ap-south-1.amazoncognito.com/signup?response_type=code&client_id=4v07k0sl1e0c3panv8v3si96eo&redirect_uri=https://gettargetit.com
//https://gettargetit.auth.ap-south-1.amazoncognito.com/signup?response_type=code&client_id=7m27m0ip6lcqne5l0pm1c6l4f6&redirect_uri=https://gettargetit.com