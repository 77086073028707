import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import FooterAdmin from "../../components/Footers/FooterAdmin.js";
import Sidebar from "./Sidebar/Sidebar.js";
import Dashboard from "./Dashboard/Dashboard.js";
import EngineerNavbar from "./Navbar/EngineerNavbar.js";
import NewService from "./Services/NewService.js";
import NewRequestCard from "./Cards/NewRequestCard.js";
import OnGoingService from "./Services/OnGoingService.js";
// views

export default function Engineer() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <EngineerNavbar />
        {/* Header */}
        
        <div className="px-4 md:px-10 mx-auto w-full">
          <Switch>
            <Route path="/engineer/dashboard" exact component={NewService} />
            <Route path="/engineer/ongs" exact component={OnGoingService} />
            <Route path="/engineer" exact component={NewService} />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
