import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { postData } from "../../../serverRequest";
import LoadingSpinner from "../../../components/Loadder";
import { Link } from "react-router-dom";

export default function VerifyUser() {
    const [errorMessages, setErrorMessages] = useState({});
    const [loading, setLoading] = useState(false);
    const [cookies, setCookie] = useCookies(['token']);
    const history = useHistory();
    let shouldRenderContent = true;

    // User Login info
    const database = [
        {
            username: "user1",
            password: "pass1"
        },
        {
            username: "user2",
            password: "pass2"
        }
    ];

    const errors = {
        uname: "invalid username",
        pass: "invalid password"
    };

    useEffect(() => {
        const cookies = document.cookie.split(';');

    }, [shouldRenderContent, history]);

    const handleSubmit = async (event) => {
        //Prevent page reload
        event.preventDefault();
        try {
            setLoading(true);
            var employee_id = localStorage.getItem('employeeId');
            let { vcode } = document.forms[0];
            let reqData = {
                'engineer_id': employee_id,
                'verification_code': vcode.value
            }
            let resData = await postData('/api/engineer/code_verification/', reqData)
            console.log("This is data")
            console.log(resData)
            if (resData['data']['code'] === 200) {
                window.location.href = '/updatepassword';
            }
            else if (resData['data']['code'] === 403) {
                setLoading(false);
                setErrorMessages({ name: "pass", message: errors.pass });
            }
            else {
                setLoading(false);
                setErrorMessages({ name: "uname", message: errors.uname });
            }

        } catch (error) {
            // Handle errors
            console.error(error);
        }
    };



    // Generate JSX code for error message
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );
    if (shouldRenderContent) {
        return (
            <>
                {/* <Navbar transparent /> */}
                <main>
                    {loading ? (
                        <LoadingSpinner></LoadingSpinner>
                    ) : (
                        <section className="relative w-full h-full py-24 min-h-screen">
                            <div
                                className="absolute top-0 w-full h-full bg-white bg-no-repeat bg-full"
                                style={{
                                    backgroundImage:
                                        "url(" + require("../../../assets/img/bg.jpg") + ")",
                                }}
                            ></div>
                            <div className="container mx-auto px-4 h-full">
                                <div className="flex content-center items-center justify-center h-full">
                                    <div className="w-full lg:w-4/12 px-4">
                                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-black-50 border-0">
                                            <div className="rounded-t mb-0" style={{ backgroundColor: '#ffb703' }}>
                                                <div className="text-center pt-2 items-center">
                                                    <img
                                                        alt="..."

                                                        src={require("../../../assets/img/Get_IT_Logo_Jpeg.jpg")}
                                                    />
                                                </div>

                                                <hr className="mt-6 border-b-1 border-blueGray-300" />
                                            </div>
                                            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                                <div className="text-white text-center mb-3 mt-8 font-bold text-lg">
                                                    <large>Verification</large>
                                                </div>
                                                <form onSubmit={handleSubmit}>
                                                    <div className="relative w-full mb-4">
                                                        <label
                                                            className="block uppercase text-white text-xs font-bold mb-3"
                                                            htmlFor="grid-password"
                                                        >
                                                            Please enter the verification code sent to your email
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            placeholder="Code"
                                                            name="vcode"
                                                            required
                                                        />
                                                        {renderErrorMessage("uname")}
                                                    </div>
                                                    <div className="text-center mt-6">
                                                        <input
                                                            className="bg-yellow text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                                            type="Submit"
                                                            value="Submit"
                                                        >

                                                        </input>
                                                    </div>


                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                    }

                </main>
                {/* <LoginFooter></LoginFooter> */}
            </>
        );
    }
}
